<template>
  <div class="app">
    <AppHeader fixed>
      <b-link class="header-logo" to="/">
        <img class="navbar-brand-full mx-4 logo" src="/img/logo.png" width="200" height="65" alt="010speed Logo" />
      </b-link>
      <b-navbar-nav v-if="this.user != null" class="fl mr-4">
        <b-nav-item v-if="this.level >= 8" class="px-3" to="/admin/users">회원관리 </b-nav-item>
        <b-nav-item v-if="this.level >= 6" class="px-3" to="/admin/distributors">
          통계관리
        </b-nav-item>
        <b-nav-item class="px-3" v-if="this.level >= 6" to="/payment/history">충전관리</b-nav-item>
        <b-nav-item class="px-3" v-if="this.level >= 6" to="/admin/recommendCodes">코드관리</b-nav-item>
        <b-nav-item class="px-3" v-if="this.level >= 6" to="/admin/banList">차단관리</b-nav-item>
        <b-nav-item class="px-3" to="/smsSend">발송</b-nav-item>
        <b-nav-item class="px-3" to="/SmsRequests">결과</b-nav-item>
        <b-nav-item class="px-3" v-if="this.level < 8" to="/payment" exact>충전</b-nav-item>
        <b-nav-item class="px-3" to="/mypage" exact>마이페이지</b-nav-item>
        <b-nav-item class="px-3" @click.prevent="logout">로그아웃</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-else class="fl mr-4">
        <b-nav-item class="px-3" to="/login">로그인</b-nav-item>
        <b-nav-item class="px-3" to="/register" exact>회원가입</b-nav-item>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <main class="main pt-4 pb-4">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <TheFooter>
        <div>
          <p class="font-weight-bold">
            &copy; 2021 스피드문자. ALL RIGHTS RESERVED.
          </p>
        </div>
      </TheFooter>
    </div>
  </div>
</template>

<script>
import { Header as AppHeader, Footer as TheFooter } from '@coreui/vue'

export default {
  name: 'full',
  components: {
    AppHeader,
    TheFooter,
  },
  data() {
    return {
      statusTimer: null,
      scrollTimeout: null,
      isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    checkNaviBarTransparent(isEnter) {
      const appHeaderEl = document.getElementsByClassName('app-header')[0]
      const appBodyEl = document.getElementsByClassName('app-body')[0]

      // clearTimeout(this.scrollTimeout)
      // this.scrollTimeout = setTimeout(function(e) {
      //   if (appBodyEl.scrollTop > 100) {
      //     return
      //   }

      //   if (isEnter) {
      //     appHeaderEl.classList.remove('transparent')
      //   } else {
      //     appHeaderEl.classList.add('transparent')
      //   }
      // }, 50)
    },
    addEvent() {
      const appHeaderEl = document.getElementsByClassName('app-header')[0]
      const t = this

      appHeaderEl.addEventListener('mouseenter', function() {
        t.checkNaviBarTransparent(true)
      })

      appHeaderEl.addEventListener('mouseleave', function() {
        t.checkNaviBarTransparent(false)
      })
    },
  },
  mounted() {
    const token = this.$cookie.get('token')
    if (token) {
      this.getUserInfo()
    }

    this.addEvent()
  },
  destroyed() {
    clearInterval(this.statusTimer)
  },
  computed: {
    name() {
      return this.$route.name
    },
    user() {
      if (this.$store.state.user) {
        clearInterval(this.statusTimer)
        this.getStatus()

        this.statusTimer = setInterval(() => {
          this.getStatus()
        }, 60 * 1000)
      }

      return this.$store.state.user
    },
    list() {
      return this.$route.matched.filter(route => route.name || route.meta.label)
    },
    level() {
      if (!this.$store.state.user) {
        return 0
      }
      return this.$store.state.user.level
    },
    isShowBoard() {
      if (!this.$store.state.user) {
        return -1
      }

      const recommendCode = this.$store.state.user.recommendCode
      const index = ['wa77', 'wa88', 'wa99', 'wa20', 'qwe', 'asd', 'zxc_0'].indexOf(recommendCode)
      return index == -1
    },
  },
  watch: {
    user() {},
    level() {},
  },
}
</script>
<style lang="scss">
.header-logo {
  display: flex;
  flex-flow: column;
  text-decoration: none !important;
}

.logo2 {
  display: none;
}

.header-logo > span:first-of-type {
  color: #8051e1;
  font-size: 27px;
  line-height: 28px;
  font-weight: 700;
}

.header-logo > span:nth-of-type(2) {
  color: #8051e1;
  display: block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: -2px;
}

.app-footer {
  flex: 0 0 80px;
}

.app-footer > div > p {
  margin-top: 8px;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 0;
  color: #7a7b7c;
}

.mobile-contact-icon {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 150px;
  height: 150px;
  border-radius: 75px;
  background-color: #8051e1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 60%;
    height: 60%;
  }
}
</style>
